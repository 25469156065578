<template>
  <div class="rank">
    <div class="header-mol">
      <div class="info">
        <h3 class="title">榜单管理</h3>
        <p class="summary">每天凌晨2点更新一次榜单数据</p>
      </div>
      <div>
        <el-button type="primary" icon="el-icon-plus" @click="showAddDialog"
          >创建榜单</el-button
        >
      </div>
    </div>
    <el-table class="body" :data="list" :row-class-name="rowStyle">
      <el-table-column
        label="排序权重"
        prop="listSort"
        width="100"
      ></el-table-column>
      <el-table-column label="榜单名称">
        <template slot-scope="scope">
          <p>{{ scope.row.name }}</p>
          <p class="tips">{{ scope.row.targetDes }}</p>
        </template>
      </el-table-column>
      <el-table-column label="浏览用户">
        <template slot-scope="scope">
          <p>
            用户{{ scope.row.viewUserCount }}人/浏览量{{
              scope.row.viewCount
            }}人次
          </p>
        </template>
      </el-table-column>
      <el-table-column label="更新人" prop="lastEditUser"></el-table-column>
      <el-table-column label="更新时间" prop="lastEditDate"></el-table-column>
      <el-table-column label="状态" class="status">
        <template slot-scope="scope">
          <p>{{ status[scope.row.closeStatus] }}</p>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120px">
        <template slot-scope="scope">
          <el-button type="text" @click="showEditDialog(scope.row.id)"
            >编辑</el-button
          >
          <el-button type="text" @click="deleRule(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :visible.sync="toggleDialog"
      class="f-dialog"
      :title="formData.ruleId ? '编辑榜单' : '创建榜单'"
      width="700px"
    >
      <el-form :model="formData" label-width="120px" ref="form">
        <el-form-item
          label="榜单名称："
          prop="name"
          :rules="[rules.required()]"
          class="form-item-flex"
        >
          <el-input
            placeholder="建议6个汉字以内"
            v-model="formData.name"
          ></el-input>
          <el-input
            placeholder="7个汉字以内, 选填"
            maxlength="7"
            v-model="formData.title"
          ></el-input>
        </el-form-item>
        <el-form-item label="一句话描述：">
          <el-input
            placeholder="建议18个汉字以内"
            v-model="formData.description"
          ></el-input>
        </el-form-item>
        <el-form-item label="自定义背景图：" class="form-item-flex">
          <div class="f-form-block">
            <el-upload
              action="none"
              class="rank-bgi-uploader xyc"
              :show-file-list="false"
              :auto-upload="false"
              :on-change="uploaderChange"
            >
              <br />
              <img
                class="img"
                v-if="formData.backgroundImage"
                :src="formData.backgroundImage"
              />
              <div class="xyc" v-else>
                <i class="el-icon-plus"></i>
                添加图片
              </div>
              <br />
            </el-upload>
            <p class="tips">
              自定义榜单背景图片，尺寸推荐4:3规格，<br />大小不超过2M；
            </p>
          </div>
        </el-form-item>
        <el-form-item
          label="关键指标："
          prop="targetType"
          :rules="[rules.required()]"
        >
          <p class="tips">请从下列指标中选择榜单排名的关键指标</p>
          <div class="f-form-block mt10">
            <el-radio-group v-model="formData.targetType">
              <el-radio
                v-for="(item, index) in targetType"
                :key="index"
                :label="item.value"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="指标别名：">
          <el-input
            class="w260"
            placeholder="指标别名"
            v-model="formData.targetAlias"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序规则：" required>
          <el-radio-group v-model="formData.sortRule">
            <el-radio-button label="1">由高到低排序</el-radio-button>
            <el-radio-button label="2">由低到高排序</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="指标要求："
          prop="targetLimit"
          :rules="[rules.required(), rules.number]"
        >
          <p class="tips">即只有符合“指标要求”的楼盘才允许上榜；</p>

          <div class="index-wrap f-form-block mt10">
            <div v-if="formData.sortRule == 1">
              <span>{{ targetType[formData.targetType - 1].label }}</span>
              <span>≥</span>
              <el-input
                placeholder="指标值"
                v-model="formData.targetLimit"
              ></el-input>
            </div>
            <div v-else>
              <span>{{ targetType[formData.targetType - 1].label }}</span>
              <span>≤</span>
              <el-input
                placeholder="指标值"
                v-model="formData.targetLimit"
              ></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          label="数量限制："
          required
          class="form-item-flex limit-form-item"
        >
          <div class="rank-count f-form-block">
            <el-form-item
              v-for="(item, index) in formData.ruleNumVos"
              :key="index"
              :prop="'ruleNumVos.' + index + '.num'"
              :rules="[rules.required(), rules.int]"
            >
              <span>{{ item.label }}</span>
              <el-input placeholder="数字" v-model.number="item.num"></el-input>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item
          label="榜单状态："
          prop="closeStatus"
          :rules="[rules.required()]"
        >
          <el-radio-group v-model="formData.closeStatus">
            <el-radio-button label="1">开启</el-radio-button>
            <el-radio-button label="2">关闭</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="榜单排序："
          prop="listSort"
          :rules="[rules.required()]"
        >
          <el-input
            class="w140"
            placeholder="数字越大越靠前"
            v-model.number="formData.listSort"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="toggleDialog = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getRulePage,
  insertRule,
  deleteRule,
  updateRule,
  getRuleDetail
} from "@/api/appManage";
import { uploadImg } from "@/api/common";
import rules from "@/utils/rules";
const formData = {
  //表单数据
  backgroundImage: "", //背景图
  closeStatus: 1, //榜单状态：1:开启 2:关闭 ,
  description: "", //描述 ,
  name: "", //榜单名称 ,
  // ruleId: null, //规则id,新增是不传 ,
  ruleNumVos: [
    //指标数量限制 ,
    {
      num: null, //数量限制 ,
      // ruleId: null, //规则id ,
      type: 1, //1:全城 2:区域 3:全城+类型 4:区域+类型
      label: "“全城”榜单，TOP"
    },
    {
      num: null,
      // ruleId: null,
      type: 2,
      label: "“区域”榜单，TOP"
    },
    {
      num: null,
      // ruleId: null,
      type: 3,
      label: "“全城”+“类型”榜单，TOP"
    },
    {
      num: null,
      // ruleId: null,
      type: 4,
      label: "“区域”+“类型”榜单，TOP"
    }
  ],
  listSort: 0, //榜单排序
  targetAlias: "", //指标别名
  sortRule: 1, //排序规则: 1:由高到低 2:由低到高 ,
  targetLimit: null, //指标最低要求
  targetType: 1, //指标类型：2:容积率 1:绿化率 3:地铁距离 4:浏览用户 5:浏览人次 ,
  title: "" //标题
};
export default {
  data() {
    return {
      rules,
      status: {
        1: "开启",
        2: "关闭"
      },
      list: [], //榜单列表
      toggleDialog: false,
      queryData: {
        //请求数据
        pageNum: 1,
        pageSize: 10
      },
      formData: JSON.parse(JSON.stringify(formData)),
      targetType: [
        //指标类型：1
        { label: "绿化率", value: 1 },
        { label: "容积率", value: 2 },
        { label: "地铁距离", value: 3 },
        { label: "浏览用户", value: 4 },
        { label: "浏览人次", value: 5 }
      ]
    };
  },
  created() {
    this.getRulePage();
  },
  methods: {
    async uploaderChange(file) {
      //上传图片
      let limitSize = 1024 * 1024 * 2;
      if (file.size > limitSize) {
        this.$showError("图片过大");
        return;
      }
      const res = await uploadImg({ file: file.raw });
      if (res) {
        this.formData.backgroundImage = res[0].fileUrl;
      }
    },
    rowStyle({ row }) {
      //表格行样式
      if (row.closeStatus == 2) {
        //如果关闭状态
        return "status-close";
      }
      return "";
    },
    showAddDialog() {
      //点击创建
      this.formData = this.$deepClone(formData);
      this.toggleDialog = true;
      this.$nextTick(() => {
        this.$refs.form.resetFields();
      });
    },
    async showEditDialog(id) {
      //点击编辑
      const res = await getRuleDetail({ id }); //获取榜单规则详情
      if (res) {
        this.toggleDialog = true;
        this.formData = res;
        //添加“数量限制”label
        this.formData.ruleNumVos = res.ruleNumVos.map(item => {
          for (let item2 of formData.ruleNumVos) {
            if (item.type === item2.type) {
              item.label = item2.label;
            }
          }
          return item;
        });
      }
    },
    async deleRule(id) {
      //删除榜单规则
      this.$confirm("删除后无法恢复，确定删除该榜单吗？", "删除榜单", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const res = await deleteRule({ id });
          if (res) {
            this.$showSuccess("删除成功");
            this.getRulePage();
          }
        })
        .catch(() => {});
    },
    async getRulePage() {
      //获取榜单规则列表
      const res = await getRulePage(this.queryData);
      if (res) {
        this.list = res.list;
      }
    },
    submit() {
      //提交表单
      this.$refs.form.validate(async valid => {
        if (!valid) {
          return;
        }
        const fn = this.formData.ruleId ? updateRule : insertRule;
        const res = await fn(this.formData);
        if (res) {
          this.$showSuccess("提交成功");
          this.formData = this.$deepClone(formData);
          this.$refs.form.resetFields();
          this.getRulePage();
          this.toggleDialog = false;
        }
      });
    }
  }
};
</script>
<style lang="scss">
.rank {
  background: #fff;
  .limit-form-item {
    .el-form-item {
      margin-top: 0;
      &:nth-child(n + 3) {
        margin-top: 20px;
      }
    }
  }
  .form-item-flex {
    .f-form-block {
      display: flex;
    }
    .el-form-item__content {
      display: flex;
      .el-input {
        & + .el-input {
          margin-left: 20px;
        }
      }
    }
  }
  .index-wrap {
    display: flex;
    & > div {
      display: flex;
      margin-right: 30px;
      & > span {
        margin-right: 15px;
      }
      .el-input {
        width: 110px;
      }
    }
  }
  .rank-count {
    display: flex;
    flex-wrap: wrap;
    & > div {
      width: 50%;
      display: flex;
      margin-top: 20px;
      .el-input {
        width: 60px;
        margin-left: 15px;
      }
    }
  }
  .body {
    .status-close {
      td {
        .cell {
          color: #999;
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
.rank {
  padding: 30px;
  min-height: 100%;
  box-shadow: 0 0 5px #ddd;
  border-radius: 5px;
  .head {
    .title {
      h3 {
        font-size: 16px;
        line-break: 16px;
        padding-left: 20px;
        border-left: 3px solid $primary;
      }
      p {
        margin-top: 10px;
        padding-left: 20px;
        color: $color9;
      }
    }
  }
  .body {
    margin-top: 30px;
    .tips {
      margin-top: 10px;
      color: #999;
    }
  }
}
.rank-bgi-uploader {
  width: 120px;
  height: 100px;
  border: 1px dashed #ccc;
  line-height: 1;
  margin-right: 20px;
  cursor: pointer;
  overflow: hidden;
  .img {
    width: 120px;
    height: 100px;
  }
  .el-icon-plus {
    margin-bottom: 15px;
    font-size: 32px;
    font-weight: bold;
    color: $primary;
  }
  &:hover {
    border-color: $primary;
  }
}
.tips {
  color: #8691a3;
}
</style>
