var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rank"
  }, [_c('div', {
    staticClass: "header-mol"
  }, [_vm._m(0), _c('div', [_c('el-button', {
    attrs: {
      "type": "primary",
      "icon": "el-icon-plus"
    },
    on: {
      "click": _vm.showAddDialog
    }
  }, [_vm._v("创建榜单")])], 1)]), _c('el-table', {
    staticClass: "body",
    attrs: {
      "data": _vm.list,
      "row-class-name": _vm.rowStyle
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "排序权重",
      "prop": "listSort",
      "width": "100"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "榜单名称"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('p', [_vm._v(_vm._s(scope.row.name))]), _c('p', {
          staticClass: "tips"
        }, [_vm._v(_vm._s(scope.row.targetDes))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "浏览用户"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('p', [_vm._v(" 用户" + _vm._s(scope.row.viewUserCount) + "人/浏览量" + _vm._s(scope.row.viewCount) + "人次 ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "更新人",
      "prop": "lastEditUser"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "更新时间",
      "prop": "lastEditDate"
    }
  }), _c('el-table-column', {
    staticClass: "status",
    attrs: {
      "label": "状态"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('p', [_vm._v(_vm._s(_vm.status[scope.row.closeStatus]))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "操作",
      "width": "120px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.showEditDialog(scope.row.id);
            }
          }
        }, [_vm._v("编辑")]), _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.deleRule(scope.row.id);
            }
          }
        }, [_vm._v("删除")])];
      }
    }])
  })], 1), _c('el-dialog', {
    staticClass: "f-dialog",
    attrs: {
      "visible": _vm.toggleDialog,
      "title": _vm.formData.ruleId ? '编辑榜单' : '创建榜单',
      "width": "700px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.toggleDialog = $event;
      }
    }
  }, [_c('el-form', {
    ref: "form",
    attrs: {
      "model": _vm.formData,
      "label-width": "120px"
    }
  }, [_c('el-form-item', {
    staticClass: "form-item-flex",
    attrs: {
      "label": "榜单名称：",
      "prop": "name",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "建议6个汉字以内"
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  }), _c('el-input', {
    attrs: {
      "placeholder": "7个汉字以内, 选填",
      "maxlength": "7"
    },
    model: {
      value: _vm.formData.title,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "title", $$v);
      },
      expression: "formData.title"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "一句话描述："
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "建议18个汉字以内"
    },
    model: {
      value: _vm.formData.description,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "description", $$v);
      },
      expression: "formData.description"
    }
  })], 1), _c('el-form-item', {
    staticClass: "form-item-flex",
    attrs: {
      "label": "自定义背景图："
    }
  }, [_c('div', {
    staticClass: "f-form-block"
  }, [_c('el-upload', {
    staticClass: "rank-bgi-uploader xyc",
    attrs: {
      "action": "none",
      "show-file-list": false,
      "auto-upload": false,
      "on-change": _vm.uploaderChange
    }
  }, [_c('br'), _vm.formData.backgroundImage ? _c('img', {
    staticClass: "img",
    attrs: {
      "src": _vm.formData.backgroundImage
    }
  }) : _c('div', {
    staticClass: "xyc"
  }, [_c('i', {
    staticClass: "el-icon-plus"
  }), _vm._v(" 添加图片 ")]), _c('br')]), _c('p', {
    staticClass: "tips"
  }, [_vm._v(" 自定义榜单背景图片，尺寸推荐4:3规格，"), _c('br'), _vm._v("大小不超过2M； ")])], 1)]), _c('el-form-item', {
    attrs: {
      "label": "关键指标：",
      "prop": "targetType",
      "rules": [_vm.rules.required()]
    }
  }, [_c('p', {
    staticClass: "tips"
  }, [_vm._v("请从下列指标中选择榜单排名的关键指标")]), _c('div', {
    staticClass: "f-form-block mt10"
  }, [_c('el-radio-group', {
    model: {
      value: _vm.formData.targetType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "targetType", $$v);
      },
      expression: "formData.targetType"
    }
  }, _vm._l(_vm.targetType, function (item, index) {
    return _c('el-radio', {
      key: index,
      attrs: {
        "label": item.value
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1)], 1)]), _c('el-form-item', {
    attrs: {
      "label": "指标别名："
    }
  }, [_c('el-input', {
    staticClass: "w260",
    attrs: {
      "placeholder": "指标别名"
    },
    model: {
      value: _vm.formData.targetAlias,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "targetAlias", $$v);
      },
      expression: "formData.targetAlias"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "排序规则：",
      "required": ""
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.formData.sortRule,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "sortRule", $$v);
      },
      expression: "formData.sortRule"
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": "1"
    }
  }, [_vm._v("由高到低排序")]), _c('el-radio-button', {
    attrs: {
      "label": "2"
    }
  }, [_vm._v("由低到高排序")])], 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "指标要求：",
      "prop": "targetLimit",
      "rules": [_vm.rules.required(), _vm.rules.number]
    }
  }, [_c('p', {
    staticClass: "tips"
  }, [_vm._v("即只有符合“指标要求”的楼盘才允许上榜；")]), _c('div', {
    staticClass: "index-wrap f-form-block mt10"
  }, [_vm.formData.sortRule == 1 ? _c('div', [_c('span', [_vm._v(_vm._s(_vm.targetType[_vm.formData.targetType - 1].label))]), _c('span', [_vm._v("≥")]), _c('el-input', {
    attrs: {
      "placeholder": "指标值"
    },
    model: {
      value: _vm.formData.targetLimit,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "targetLimit", $$v);
      },
      expression: "formData.targetLimit"
    }
  })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.targetType[_vm.formData.targetType - 1].label))]), _c('span', [_vm._v("≤")]), _c('el-input', {
    attrs: {
      "placeholder": "指标值"
    },
    model: {
      value: _vm.formData.targetLimit,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "targetLimit", $$v);
      },
      expression: "formData.targetLimit"
    }
  })], 1)])]), _c('el-form-item', {
    staticClass: "form-item-flex limit-form-item",
    attrs: {
      "label": "数量限制：",
      "required": ""
    }
  }, [_c('div', {
    staticClass: "rank-count f-form-block"
  }, _vm._l(_vm.formData.ruleNumVos, function (item, index) {
    return _c('el-form-item', {
      key: index,
      attrs: {
        "prop": 'ruleNumVos.' + index + '.num',
        "rules": [_vm.rules.required(), _vm.rules.int]
      }
    }, [_c('span', [_vm._v(_vm._s(item.label))]), _c('el-input', {
      attrs: {
        "placeholder": "数字"
      },
      model: {
        value: item.num,
        callback: function callback($$v) {
          _vm.$set(item, "num", _vm._n($$v));
        },
        expression: "item.num"
      }
    })], 1);
  }), 1)]), _c('el-form-item', {
    attrs: {
      "label": "榜单状态：",
      "prop": "closeStatus",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.formData.closeStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "closeStatus", $$v);
      },
      expression: "formData.closeStatus"
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": "1"
    }
  }, [_vm._v("开启")]), _c('el-radio-button', {
    attrs: {
      "label": "2"
    }
  }, [_vm._v("关闭")])], 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "榜单排序：",
      "prop": "listSort",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-input', {
    staticClass: "w140",
    attrs: {
      "placeholder": "数字越大越靠前"
    },
    model: {
      value: _vm.formData.listSort,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "listSort", _vm._n($$v));
      },
      expression: "formData.listSort"
    }
  })], 1)], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.toggleDialog = false;
      }
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("确 定")])], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info"
  }, [_c('h3', {
    staticClass: "title"
  }, [_vm._v("榜单管理")]), _c('p', {
    staticClass: "summary"
  }, [_vm._v("每天凌晨2点更新一次榜单数据")])]);

}]

export { render, staticRenderFns }