import request from "@/utils/request";

// 获取oss上传参数
export const getOSSParam = () =>
  request({
    url: "/api/v1/upload/getOssToken",
    showLoading: false
  });

// 上传图片
export const uploadImg = data =>
  request({
    url: `/api/v1/upload/img`,
    dataType: "formData",
    data
  });

//获取城市
export const getSiteList = data =>
  request({
    url: `/api/v1/fxb/area/getSiteList`,
    data,
    params: data
  });

//根据城市获取商圈
export const getAreaListBySite = data =>
  request({
    url: `/api/v1/fxb/area/getAreaListBySite`,
    data
  });

// 根据支付方式获取对应的支付选项数据 如银行 pos机
export const getPayOptionsByPayWay = params =>
  request({
    url: "/api/v1/platform/web/payway/list",
    params
  });

// 自定义图片文字校验接口 这个接口存在跨域问题，后端没找到问题，所以用fetch临时解决
// export const checkImgNWord = data => {
//   return new Promise(resolve => {
//     fetch("https://api.fangyibao.cn/extensionapi/prondetection", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json"
//       },
//       body: JSON.stringify(data)
//     })
//       .then(res => res.json())
//       .then(resolve);
//   });
// };

export const checkImgNWord = data => {
  return request({
    url: `/api/v1/upload/multi/check`,
    data,
    originalData: true
  });
};

//请求代理请求 主要用作 高德地图之类的api请求，后端做缓存
export const requestProxy = url =>
  request({
    url: `/api/v1/fxb/httpProxy/requestProxy`,
    method: "GET",
    params: { url },
    originalData: true,
    showLoading: false
  });
